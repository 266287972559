<!-- eslint-disable vue/no-template-shadow -->
<template>
  <v-row class="match-height">
    <v-col cols="12">
      <app-card-code :title="`${$t('menu.nomenclador')} / ${$t('menu.hotels')} / ${$t('lbl.suplementReducc')}`">
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="error"
                class="mb-4 me-3"
                @click="$router.push({ name: 'suplementos-hotels-list' })"
              >
                <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
                <span>{{ $t('btn.back') }}</span>
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                v-if="!$store.state.app.onlyShow"
                color="primary"
                class="mb-4 me-3"
                :loading="loading"
                @click="save()"
              >
                <span>{{ btnTitle }}</span>
                <v-icon>{{ icons.mdiUpdate }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-form
            v-if="!isLoading"
            class="multi-col-validation"
          >
            <v-row>
              <v-col
                cols="12"
                md="3"
              >
                <v-select
                  v-model="item.type"
                  :items="types"
                  :label="$t('lbl.tipo')"
                  outlined
                  dense
                  hide-details
                  item-text="name"
                  item-value="slug"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        {{ $t('lbl.tipo') }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-select>
              </v-col>
              <v-col
                cols="12"
                md="9"
              >
                <v-text-field
                  v-model="item.name"
                  :label="$t('lbl.name')"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                md="3"
              >
                <v-select
                  v-model="item.model"
                  :items="models"
                  :label="$t('menu.modelo')"
                  outlined
                  dense
                  hide-details
                  item-text="name"
                  item-value="slug"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        {{ $t('menu.modelo') }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-select>
              </v-col>
              <v-col
                cols="12"
                md="9"
              >
                <v-img
                  v-if="item.model === 'alojamiento'"
                  contain
                  width="100%"
                  lazy-src="@/assets/models/alojamiento1.jpg"
                  src="@/assets/models/alojamiento1.jpg"
                >
                </v-img>
                <v-img
                  v-if="item.model === 'plan-alimentos'"
                  contain
                  width="100%"
                  lazy-src="@/assets/models/plan-alimentos1.jpg"
                  src="@/assets/models/plan-alimentos1.jpg"
                >
                </v-img>
                <v-img
                  v-if="item.model === 'check-in-out'"
                  contain
                  width="100%"
                  lazy-src="@/assets/models/check-in.jpg"
                  src="@/assets/models/check-in.jpg"
                >
                </v-img>
                <v-img
                  v-if="item.model === 'temporada-pico'"
                  contain
                  width="100%"
                  lazy-src="@/assets/models/season.jpg"
                  src="@/assets/models/season.jpg"
                >
                </v-img>
                <v-img
                  v-if="item.model === 'cena'"
                  contain
                  width="100%"
                  lazy-src="@/assets/models/cena.jpg"
                  src="@/assets/models/cena.jpg"
                >
                </v-img>
                <v-img
                  v-if="item.model === 'traslado'"
                  contain
                  width="100%"
                  lazy-src="@/assets/models/traslado.jpg"
                  src="@/assets/models/traslado.jpg"
                >
                </v-img>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </app-card-code>
    </v-col>
  </v-row>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
// eslint-disable-next-line import/no-unresolved
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'

import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiTrashCan,
  mdiAirplaneTakeoff,
  mdiCity,
  mdiMapPlus,
  mdiMapMinus,
  mdiInformationOutline,
} from '@mdi/js'

export default {
  components: {
    AppCardCode,
  },
  data() {
    return {
      isLoading: true,
      editGeoTag: true,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiTrashCan,
        mdiAirplaneTakeoff,
        mdiCity,
        mdiMapPlus,
        mdiMapMinus,
        mdiInformationOutline,
      },
      models: [],
      types: [],
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      modelTimeConfig: {
        type: 'string',
        mask: 'HH:mm',
      },
      item: {
        type: null,
      },
      showMap: true,
      loading: false,

      categories: [],
      proveedores: [],
      itemsProveedor: [],
      searchProveedor: null,
    }
  },
  computed: {
    btnTitle() {
      return sessionStorage.getItem('suplementos-hotels-id') === null ? this.$t('btn.send') : this.$t('btn.update')
    },
    ...mapState({
      onlyShow: state => state.app.onlyShow,
    }),
  },
  watch: {
    searchProveedor(val) {
      if (val !== null && val.length > 0) {
        this.filterProveedor(val.toLowerCase())
      }
    },
  },
  created() {
    this.types.push({
      name: 'Suplemento',
      slug: 'suplement',
    })
    this.types.push({
      name: 'Reducción',
      slug: 'reduccion',
    })

    this.models.push({
      name: 'Alojamiento',
      slug: 'alojamiento',
    })
    this.models.push({
      name: 'Plan alimentos',
      slug: 'plan-alimentos',
    })
    this.models.push({
      name: 'Check-In/Out',
      slug: 'check-in-out',
    })
    this.models.push({
      name: 'Temporada pico',
      slug: 'temporada-pico',
    })
    this.models.push({
      name: 'Cena',
      slug: 'cena',
    })
    this.models.push({
      name: 'Traslado',
      slug: 'traslado',
    })

    if (sessionStorage.getItem('suplementos-hotels-show') !== null) {
      this.setOnlyShow(true)
    } else {
      this.setOnlyShow(false)
    }
    this.getItem()
  },
  methods: {
    ...mapMutations(['setOnlyShow']),

    getItem() {
      if (sessionStorage.getItem('suplementos-hotels-id') !== null) {
        const id = sessionStorage.getItem('suplementos-hotels-id')
        this.axios
          .get(`nom_suplemento_contrate/${id}`, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            this.item = res.data.data.data
            if (this.item.is_reduction) {
              this.item.type = 'reduccion'
            } else {
              this.item.type = 'suplement'
            }
          })
          // eslint-disable-next-line no-return-assign
          .finally(() => (this.isLoading = false))
      } else {
        this.isLoading = false
      }
    },

    save() {
      if (this.item.name && this.item.model && this.item.type) {
        this.loading = true
        this.item.for_hotel = true
        if (this.item.type === 'suplement') {
          this.item.is_reduction = false
        } else {
          this.item.is_reduction = true
        }
        this.item.perfil_slug = sessionStorage.getItem('perfil')

        if (sessionStorage.getItem('suplementos-hotels-id') === null) {
          this.axios
            .post('nom_suplemento_contrate', this.item, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(response => {
              if (response.data.success === false) {
                if (response.data.data.status === 401) {
                  // this.$toast.error(this.$t('msg.noAutorice'))
                } else if (response.data.data.status === 402) {
                  this.$toast.error(
                    this.$t('msg.nom_suplemento_contrate_fligthsExist', { identificador: this.item.modelo }),
                  )
                }
              } else {
                this.$toast.success(this.$t('msg.infoSuccess'))
              }
            })
            .catch(error => console.log(error))
            // eslint-disable-next-line no-return-assign
            .finally(() => this.$router.push({ name: 'suplementos-hotels-list' }))
        } else {
          this.axios
            .put(`nom_suplemento_contrate/${this.item.id}`, this.item, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(response => {
              if (response.data.success === false) {
                // this.$toast.error(this.$t('msg.noAutorice'))
              } else {
                this.$toast.success(this.$t('msg.infoSuccess'))
              }
            })
            .catch(error => console.log(error))
            // eslint-disable-next-line no-return-assign
            .finally(() => this.$router.push({ name: 'suplementos-hotels-list' }))
        }
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },
  },
}
</script>
